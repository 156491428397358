<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10706')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-close">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<div class="sb_auth_container">
		<img src="@/assets/images/Sbdeajeon_auth.png" alt="H_SALAD">
		<div>
			<span><b>SK엠앤서비스㈜</b>는 [2022년도 대한민국 숙박대전 사업]의 운영사로 2022년도 대한민국 숙박대전 서비스 제공을 위해 아래와 같이 귀하의 개인정보를 수집·이용 및 제3자에 제공합니다. 해당 내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.</span>
			<!-- 재번역체크 -->
			<ul class="agrees" >
				<li>
					<div class="checkbox">
						<label>
							<input type="checkbox" class="chk blind" :checked="allChecked" @change="allCheck($event)">
							<span class="checkbox__bg"></span>
							<span class="agree__title">{{t('10720')}}</span>
						</label>
					</div>
				</li>
				<li>
					<div class="checkbox">
						<label>
							<input type="checkbox" class="chk blind" value="privacy" v-model="checkedList">
							<span class="checkbox__bg"></span>
							<span class="agree__title">{{t('10116')}}</span>
						</label>
					</div>
					<button type="button">
						<i class="icon icon-right--light" @click="handleOpenTerm('agree')">{{t('10115')}}</i>
					</button>
				</li>
				<li>
					<div class="checkbox">
						<label>
							<input type="checkbox" class="chk blind" value="privacy3" v-model="checkedList">
							<span class="checkbox__bg"></span>
							<span class="agree__title">{{t('10488')}}</span>
						</label>
					</div>
					<button type="button">
						<i class="icon icon-right--light" @click="handleOpenTerm('third')">{{t('10115')}}</i>
					</button>
				</li>
			</ul>
		</div>
		<a class="SB_subbit" @click="handleClickSB">{{t('10708')}}
            <i style="margin-top:13px;" class="icon icon-right--white"></i>
		</a>
		<a class="SB_back" @click="goBack"> {{t('10709')}}
		</a>
	</div>
	<!-- // nav -->
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TermsAgree v-if="openTerm === 'agree'" :handleClose="handleCloseTerm" />
	<TermsThird v-if="openTerm === 'third'" :handleClose="handleCloseTerm" />
</template>
<style>
	.wrap{padding-bottom:0px;}
</style>
<script>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs'
import "dayjs/locale/ko";
import mpj from "@/urls/mpj01";
import ErrorAlert from "@/components/layers/Message_alert";
import numberWithCommas from "@/utils/numberWithCommas";
import TermsThird from "@/components/layers/Terms_third";
import TermsAgree from "@/components/layers/Terms_agree";
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 

dayjs.locale("ko");

export default {
	components: {
		ErrorAlert,
		TermsAgree,
		TermsThird
	},
	setup: function() {
		const store = useStore();
		const selectedMenuId = ref("01");
		const router = useRouter();
		const route = useRoute();
		const locaition1 = ref("");
		const locaition2 = ref("");
		const openErrorAlert = ref(false)
		const checkedList = ref([]);
		const openTerm = ref(null);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const userData = computed(() => store.state.userData);
		const { t }= useI18n() 


        onMounted(() => {
            store.dispatch("common/fetchCd086")
            // store.dispatch("common/fetchta111")
        })
		
		const menus = computed(() => store.state.common.cd086.reduce((acc, cur) => {
				const isIn = acc.find(item => item.id === cur.area_cd1);
				if (!isIn && cur.area_cd1 !== "00") {
					acc.push({id: cur.area_cd1, name: cur.area_nm1});
				}
				return acc;
			}, []));

		const locations = computed(() => store.state.common.cd086
			.filter((location) => location.area_cd1 === selectedMenuId.value )
			.reduce((acc, cur) => {
				if(cur.area_cd1 !== "00") {
					acc.push({id: cur.area_cd2, name: cur.area_nm2, count: numberWithCommas(cur.hotel_cnt)});
				}
				return acc;
			}, []));
		
		const handleMenu = (menuId) => {
			if(menuId === '01')
			selectedMenuId.value = menuId;
		};

		const value = ['privacy3', 'privacy'];
		const allChecked = computed(
			() => (value
				.filter(checked => checkedList.value.includes(checked)))
			.length === 2
		);

		const allCheck = (event) => {
			if (event.target.checked) {
				checkedList.value = value;
			} else {
				checkedList.value = [];
			}
		};
		

		const handleSelectLocation = (locationId) => {
			//cd086안의 "전체 전체"라는 이상한 값 필터
			const location = store.state.common.cd086.filter(item => item.area_cd1 !== "00").find(item => item.area_cd2 === locationId);
			if (location) {
				store.commit("mhm01/SET_SELECT_LOCATION", {location});
			}
			if(route.query.callbackUrl) {
				router.replace(route.query.callbackUrl);
			} else {
				router.back();
			}
		};

		
		const handleClickSB = () => {
			
			if(!allChecked.value) {
				//alert("이용약관에 모두 동의해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "이용약관에 모두 동의해주세요."});
				openErrorAlert.value = true;
				return;
			}
			//const url = (window.location.hostname === "devpick.smartdesk.kr" ? "http://devcms.hotelsalad.com" :  "https://app.1H_SALAD.co.kr");
			var IMP = window.IMP; // 생략 가능
  			IMP.init("imp40330180"); // 예: imp00000000
			IMP.certification({ // param
				merchant_uid: "ORD20180131-0000011", // 주문 번호
				//m_redirect_url : url + "/layers/Sbdeajeon/auth", // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
				popup : false, // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
				is_iframe: true,
			}, function(rsp) {
                if ( rsp.success ) {
					mpj.getpj100({imp_uid: rsp.imp_uid,mem_id: userData.value.mem_id })
						.then((data) => {
							if(data.data.Res_cd === "0000") {
								store.commit("SET_USERDATA", {userData: {
									...userData.value,
									mem_ci: data.data.Res_data[0].mem_ci,
									mem_gender: data.data.Res_data[0].mem_gender,
									mem_brith: data.data.Res_data[0].mem_brith,
								}});
								//alert('인증 성공했습니다.')
								router.push('/layers/Sbdeajeon')
							}else{
								//alert('인증 실패했습니다.')
								store.commit("app/SET_RESMESSAGE", {res_message: null});
								store.commit("app/SET_RESMESSAGE", {res_message: "인증 실패했습니다."});
								openErrorAlert.value = true;
							}
						})
            		}
				})
		}
		return {
			menus,
			selectedMenuId,
			locations,
			locaition1,
			locaition2,
			openTerm,
			checkedList,
			allCheck,
			allChecked,
			openErrorAlert,
			t,  //번역필수 모듈
		  i18n, 
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			handleOpenTerm: (type) => {
				openTerm.value = type;
			},
			handleCloseTerm: () => {
				openTerm.value = null;
			},
			handleMenu,
			handleSelectLocation,
			userData,
			isLogin,
			handleClickSB,
			goBack (){
				this.$router.back()
			}
		}
	}
}
</script>